.custom-icon-spacing {
    margin-right: 20px;
}
.custom-table{
    background-color: #fe0000;
}
.custom-button-spacing{
    margin-left: 7px;
}
.icon-container {
    display: none;
}

tr:hover .icon-container {
    display: inline-block;
}

.fa-icon-location {
    margin-left: 6px;
    background-color: white;
    border: 2px solid white;
    padding: 4px;
    border-radius: 3px;
}

.fa-icon-guard {
    margin-left: 6px;
    background-color: white;
    border: 2px solid white;
    padding: 4px;
    border-radius: 3px;
}
.circular-avatar {
    width: 100px; 
    height: 130px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(1.5);
}

.menu-appbar-dropdown {
    background-color: #154d32 !important;
    color: white !important;
    
}

.chart-container{
    margin-left: 20px;
}
.chart-container-pie{
    border-radius: 5px;
    height: 250px;
    width: 250px;
}  
.pie-heading{
    padding-left: 14px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(240, 240, 240);
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  
  
  